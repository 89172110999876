import { IRoleTypeBE } from "@datalex-software-as/datalex-client";

export class RoleFilterProvider
{
    constructor() {}
    
    public filterRoleTypesByCategoryId(roleTypes: IRoleTypeBE[], categoryId: string): IRoleTypeBE[] {
        return  roleTypes.filter((type) => type.RoleTypeCategoryId.includes(categoryId));
    }

    
}
